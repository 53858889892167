<template>
    <v-tabs hide-slider centered show-arrows height="44" class="tab--underline-inline">
        <slot />
    </v-tabs>
</template>

<script>
export default {
    props: {
    },
};
</script>

<style scoped>
</style>
