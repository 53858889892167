<template>
    <v-tabs-underline-inline>
        <v-tab exact-path to="/mypage/myinfo"><v-btn text>Information</v-btn></v-tab>
        <v-tab exact-path to="/mypage/product-inquiries"><v-btn text>Product Inquiries</v-btn></v-tab>
        <v-tab exact-path to="/mypage/leave"><v-btn text>Delete Account</v-btn></v-tab>
        <v-tab exact-path to="/mypage/payments"><v-btn text>Payment History</v-btn></v-tab>
        <v-tab exact-path to="/mypage/academy-favorites"><v-btn text>Liked Lectures</v-btn></v-tab>
        <v-tab exact-path to="/mypage/academy-coupons"><v-btn text>Coupons</v-btn></v-tab>
    </v-tabs-underline-inline>
</template>
<script>
import VTabsUnderlineInline from "@/components/client/dumb/v-tabs-underline-inline.vue";

export default {
    components: {
        VTabsUnderlineInline,
    },
};
</script>
