<template>
    <client-page>
        <template #subHead>
            <sub-visual title="MYPAGE">
                <template slot="onlyTitle">
                    <mypage-navigation></mypage-navigation>
                </template>
            </sub-visual>
        </template>

        <v-container>
            <div class="tit-wrap mb-20 mb-md-30 mb-lg-40">
                <h2 class="tit tit--sm">Payment History</h2>
            </div>
            <v-divider class="border-2 grey-16"/>

            <!-- <div class="mt-30 mt-md-40">
                <v-row justify="center">
                    <v-col cols="auto">There is no payment history</v-col>
                </v-row>
            </div> -->

            <div>
                <v-row>
                    <v-col cols="12" sm="5" md="3" align-self="center">
                        <div class="pt-16 py-md-34">
                            <v-img :aspect-ratio="1 / 1" src="">
                                <v-overlay absolute z-index="0">
                                    <v-icon large>mdi-image-broken</v-icon>
                                </v-overlay>
                            </v-img>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="7" md="6" align-self="center">
                        <div class="px-md-20 px-lg-40 pt-sm-16 py-md-34">
                            <div class="tit tit--xs text-truncate mb-4 mb-md-6">seminar name</div>
                            <v-divider class="my-14 my-md-26" />

                            <data-list-item termMd="4" termLg="3" termXl="3" descMd="8" descLg="9" descXl="9" term="Speaker" desc="Hong Gil Dong" />
                            <data-list-item termMd="4" termLg="3" termXl="3" descMd="8" descLg="9" descXl="9" term="Payment date" desc="2022-06-07 12:00:00" />
                            <data-list-item termMd="4" termLg="3" termXl="3" descMd="8" descLg="9" descXl="9" term="Viewable date" desc="2022-09-01 ~ 2022-09-30 (30 days)" />
                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="3">
                        <div class="d-flex align-center text-center h-100 grey-f8 px-12 py-16">
                            <v-row align="center" justify="space-between">
                                <v-col cols="auto" md="12">
                                    <div class="d-inline-flex align-center font-size-14 font-size-md-16 font-spoqa">
                                        <span class="font-size-18 font-size-md-22 font-weight-medium secondary--text">1,200,000</span>
                                        <span class="pl-4 pl-md-6">won</span>
                                    </div>
                                </v-col>
                                <v-col cols="auto" md="12">
                                    <v-row class="row--small">
                                        <v-col cols="12">
                                            <v-btn small outlined color="grey-d" to="/mypage/invoice" class="w-120px">Invoice<i class="icon icon-down ml-6"></i></v-btn>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-btn small color="secondary" to="" class="w-120px">Watch</v-btn>
                                            <!-- <v-btn small color="grey-d" to="" class="w-120px">Watch</v-btn> -->
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
                <v-divider />
            </div>

        </v-container>
    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";
import DataListItem from "@/components/dumb/data-list-item.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        MypageNavigation,
        DataListItem,
    },
    data: () => ({
    }),
    computed: {
    },
    mounted() {
    },
    watch: {
    },
    methods: {
    },
};
</script>

<style></style>
