var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "font-size-14 font-size-md-16 font-spoqa grey-90--text",
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', {
    staticClass: "pr-4"
  }, [_vm._v("·")])]), _c('v-col', [_vm._v(_vm._s(_vm.text))])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }