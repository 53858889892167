<template>
    <v-col cols="6" sm="4" lg="3">
        <v-card-default>
            <v-img :aspect-ratio="280 / 158" src="/images/common/coupons-bg.png" class="d-flex align-center">
                <div class="px-20 px-sm-30 px-md-40">
                    <div class="font-size-18 font-size-md-20 font-size-xl-30 font-weight-bold">USD <strong class="font-size-20 font-size-md-30 font-size-xl-44">{{ coupon.amount }}</strong></div>
                    <div class="font-size-16 font-size-md-18 font-size-xl-20 line-height-12 grey-6--text">{{ coupon.name }}</div>
                </div>
            </v-img>

            <template slot="vCardDefaultContents">
                <div class="font-size-16 font-size-md-18 font-weight-medium">{{ coupon.desc }}</div>
                <div class="v-card--default__date">{{ coupon?.applicationPeriod }}</div>
            </template>
            <template slot="footer">
                <v-btn small block :class="footerClass">{{ coupon?.status?.replace?.(/\w+/g, (w) => w[0].toUpperCase() + w.slice(1).toLowerCase()) }}</v-btn>
            </template>
        </v-card-default>
    </v-col>
</template>

<script>
const path = "/academy/on-demand";
import VCardDefault from "@/components/client/dumb/v-card-default.vue";
import { ACADEMY_COUPON_STATUSES } from "@/assets/variables";

export default {
    components: {
        VCardDefault,
    },
    props: {
        coupon: { type: Object, default: () => ({}) },
    },
    data: () => ({
        path,
    }),
    computed: {
        footerClass() {
            switch (this.coupon.status) {
                case ACADEMY_COUPON_STATUSES.APPLIED.value:
                    return "grey darken-2 white--text";
                case ACADEMY_COUPON_STATUSES.EXPIRED.value:
                case ACADEMY_COUPON_STATUSES.REVOKED.value:
                case ACADEMY_COUPON_STATUSES.WAITING.value:
                case ACADEMY_COUPON_STATUSES.DISABLED.value:
                    return "grey lighten-2";
                case ACADEMY_COUPON_STATUSES.APPLICABLE.value:
                    return "secondary";
            }
        },
    },
};
</script>

<style></style>
