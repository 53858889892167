<template>
    <client-page class="app--mypage">
        <template v-slot:subHead>
            <sub-visual title="MYPAGE">
                <template slot="onlyTitle">
                    <mypage-navigation></mypage-navigation>
                </template>
            </sub-visual>
        </template>

        <!-- 페이지 콘텐츠 -->
        <v-container>
            <div class="tit-wrap mb-20 mb-md-30 mb-lg-40">
                <h2 class="tit tit--sm">Product Inquiries</h2>
            </div>

            <v-data-table v-bind="{ ...tableAttrs, items, headers }" @click:row="({ _id }) => $router.push(`/mypage/product-inquiries/${_id}`)" class="v-data-table--default">
                <template v-for="header in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value }"> {{ header.formatter(value) }} </template>
                <template #[`item.reply`]="{ value }">
                    <v-btn v-if="!value" x-small block><span>Waiting</span></v-btn>
                    <v-btn v-if="value" x-small block color="primary">Answered</v-btn>
                </template>
            </v-data-table>

            <div class="v-pagination-wrap">
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" @input="search()" />
            </div>

            <v-fade-transition>
                <v-overlay v-show="loading" absolute color="white" light>
                    <v-progress-circular size="100" width="2" indeterminate color="primary" />
                </v-overlay>
            </v-fade-transition>
        </v-container>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";
import { tableAttrs } from "@/assets/variables";
import api from "@/api";
const headers = [
    { align: "center", text: "#", value: "index" },
    { align: "center", text: "Products of Interest", value: "meta.categories", formatter: (value = []) => value.map(({ name }) => name).join(", "), cellClass: "text-truncate" },
    { align: "center", text: "Product Name", value: "meta.products", formatter: (value = []) => value.map(({ name }) => name).join(", "), cellClass: "text-truncate" },
    { align: "center", text: "Date", value: "createdAt", formatter: (value) => value.toDate() },
    { align: "center", text: "Answer", value: "reply" },
];

export default {
    components: {
        ClientPage,
        SubVisual,
        MypageNavigation,
    },
    data() {
        return {
            loading: false,
            tableAttrs,
            headers,

            page: +this.$route.query.page || 1,
            totalCount: 0,
            limit: 10,

            filter: {},

            forms: [],
        };
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        pageCount() {
            return Math.ceil(this.totalCount / this.limit) || 1;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        items() {
            return (this.forms || []).map((item, index) => ({ ...item, index: +this.totalCount - (+this.page - 1) * +this.limit - index }));
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            if (!this.accessToken) {
                this.$router.push({
                    path: "/login",
                    params: this.$router.params,
                });
            }
            this.search(false);
        },

        async search(routable = true) {
            this.loading = true;
            const { page, limit, skip } = this;
            if (routable) this.$router.push({ query: Object.assign({}, this.filter, { page }) });

            var {
                summary: { totalCount },
                forms,
            } = await api.v1.forms.gets({
                headers: { skip, limit },
                params: { ...this.filter, isMine: true, code: "product-inquiry" },
            });

            this.forms = forms;
            this.totalCount = totalCount;

            this.loading = false;
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    tr {
        cursor: pointer;
    }
    td.text-truncate {
        max-width: 0 !important;
    }
}
</style>
