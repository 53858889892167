var render = function render(){
  var _vm$lecture, _vm$lecture2, _vm$lecture2$thumb, _vm$lecture3, _vm$lecture3$thumb, _vm$lecture4, _vm$lecture5, _vm$lecture5$createdA, _vm$lecture5$createdA2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4",
      "lg": "3"
    }
  }, [_c('v-card-default', {
    attrs: {
      "to": `${_vm.path}/${(_vm$lecture = _vm.lecture) === null || _vm$lecture === void 0 ? void 0 : _vm$lecture._id}`
    }
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 280 / 156,
      "src": (_vm$lecture2 = _vm.lecture) === null || _vm$lecture2 === void 0 ? void 0 : (_vm$lecture2$thumb = _vm$lecture2.thumb) === null || _vm$lecture2$thumb === void 0 ? void 0 : _vm$lecture2$thumb.url
    }
  }, [_c('v-overlay', {
    attrs: {
      "absolute": "",
      "z-index": "0"
    }
  }, [(_vm$lecture3 = _vm.lecture) !== null && _vm$lecture3 !== void 0 && (_vm$lecture3$thumb = _vm$lecture3.thumb) !== null && _vm$lecture3$thumb !== void 0 && _vm$lecture3$thumb.url ? _c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-play-circle-outline")]) : _c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-image-broken")])], 1), _c('div', {
    staticClass: "text-right pt-md-8 pr-md-8"
  }, [_c('academy-favorite-btn', _vm._b({
    attrs: {
      "icon": ""
    }
  }, 'academy-favorite-btn', {
    lecture: _vm.lecture
  }, false), [_c('i', {
    staticClass: "icon icon-heart-secondary",
    attrs: {
      "slot": "filled-icon"
    },
    slot: "filled-icon"
  }), _c('i', {
    staticClass: "icon icon-heart-outlined",
    attrs: {
      "slot": "empty-icon"
    },
    slot: "empty-icon"
  })])], 1)], 1), _c('template', {
    slot: "vCardDefaultContents"
  }, [_c('div', {
    staticClass: "v-card--default__tit"
  }, [_vm._v(_vm._s((_vm$lecture4 = _vm.lecture) === null || _vm$lecture4 === void 0 ? void 0 : _vm$lecture4.name))]), _c('div', {
    staticClass: "v-card--default__date"
  }, [_vm._v(_vm._s((_vm$lecture5 = _vm.lecture) === null || _vm$lecture5 === void 0 ? void 0 : (_vm$lecture5$createdA = _vm$lecture5.createdAt) === null || _vm$lecture5$createdA === void 0 ? void 0 : (_vm$lecture5$createdA2 = _vm$lecture5$createdA.toDate) === null || _vm$lecture5$createdA2 === void 0 ? void 0 : _vm$lecture5$createdA2.call(_vm$lecture5$createdA)))])])], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }