var render = function render(){
  var _vm$coupon, _vm$coupon2, _vm$coupon2$status, _vm$coupon2$status$re;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "4",
      "lg": "3"
    }
  }, [_c('v-card-default', [_c('v-img', {
    staticClass: "d-flex align-center",
    attrs: {
      "aspect-ratio": 280 / 158,
      "src": "/images/common/coupons-bg.png"
    }
  }, [_c('div', {
    staticClass: "px-20 px-sm-30 px-md-40"
  }, [_c('div', {
    staticClass: "font-size-18 font-size-md-20 font-size-xl-30 font-weight-bold"
  }, [_vm._v("USD "), _c('strong', {
    staticClass: "font-size-20 font-size-md-30 font-size-xl-44"
  }, [_vm._v(_vm._s(_vm.coupon.amount))])]), _c('div', {
    staticClass: "font-size-16 font-size-md-18 font-size-xl-20 line-height-12 grey-6--text"
  }, [_vm._v(_vm._s(_vm.coupon.name))])])]), _c('template', {
    slot: "vCardDefaultContents"
  }, [_c('div', {
    staticClass: "font-size-16 font-size-md-18 font-weight-medium"
  }, [_vm._v(_vm._s(_vm.coupon.desc))]), _c('div', {
    staticClass: "v-card--default__date"
  }, [_vm._v(_vm._s((_vm$coupon = _vm.coupon) === null || _vm$coupon === void 0 ? void 0 : _vm$coupon.applicationPeriod))])]), _c('template', {
    slot: "footer"
  }, [_c('v-btn', {
    class: _vm.footerClass,
    attrs: {
      "small": "",
      "block": ""
    }
  }, [_vm._v(_vm._s((_vm$coupon2 = _vm.coupon) === null || _vm$coupon2 === void 0 ? void 0 : (_vm$coupon2$status = _vm$coupon2.status) === null || _vm$coupon2$status === void 0 ? void 0 : (_vm$coupon2$status$re = _vm$coupon2$status.replace) === null || _vm$coupon2$status$re === void 0 ? void 0 : _vm$coupon2$status$re.call(_vm$coupon2$status, /\w+/g, function (w) {
    return w[0].toUpperCase() + w.slice(1).toLowerCase();
  })))])], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }