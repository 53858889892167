var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "MYPAGE"
          }
        }, [_c('template', {
          slot: "onlyTitle"
        }, [_c('mypage-navigation')], 1)], 2)];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap mb-20 mb-md-30 mb-lg-40"
  }, [_c('h2', {
    staticClass: "tit tit--sm"
  }, [_vm._v("Liked Lectures")])]), _c('v-divider', {
    staticClass: "border-2 grey-16 mb-30 mb-md-40"
  }), _vm.favorites.length < 1 ? [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("You have no Wish item stored")])], 1)] : [_c('v-row', _vm._l(_vm.favorites, function (_ref) {
    var lecture = _ref.lecture;
    return _c('on-demand-list-item', _vm._b({
      key: lecture._id
    }, 'on-demand-list-item', {
      lecture
    }, false));
  }), 1), _c('pagination-component', {
    attrs: {
      "count": _vm.pageCount,
      "value": _vm.page
    },
    on: {
      "change": function (page) {
        return _vm.$router.replace({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })]], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }