<template>
    <v-btn v-bind="{ ...$attrs, loading }" @click.prevent="toggleFavorite" small icon tile color="grey-d">
        <slot v-if="isFavored" name="filled-icon" />
        <slot v-else name="empty-icon" />
        <login-to-continue ref="LoginToContinue" />
    </v-btn>
</template>

<script>
import api from "@/api";
import { ACADEMY_FAVORITE_TYPES } from "@/assets/variables";
import LoginToContinue from "./login-to-continue.vue";
export default {
    components: {
        LoginToContinue,
    },
    props: {
        type: { type: String, default: ACADEMY_FAVORITE_TYPES.LECTURE.value },
        lecture: { type: Object, default: () => ({}) },
    },
    data: () => ({
        loading: false,
        favorite: undefined,
    }),
    computed: {
        isFavored() {
            return !!this.favorite;
        },
        hasUserLoggedIn() {
            return !!this.$store.state.payload?._user;
        },
    },
    mounted() {
        this.search();
    },
    methods: {
        async search() {
            if (this.hasUserLoggedIn) {
                this.loading = true;
                let _lecture = this.lecture?._id;
                let favorite = (await api.v1.me.academyFavorites.gets({ params: { _lecture } }))?.favorites?.[0];
                this.favorite = favorite;
                this.loading = false;
            }
        },
        async toggleFavorite() {
            if (!this.hasUserLoggedIn) {
                this.$refs["LoginToContinue"].shows = true;
                return;
            } else {
                try {
                    this.loading = true;
                    if (this.isFavored) await api.v1.me.academyFavorites.delete({ _id: this.favorite?._id });
                    else await api.v1.me.academyFavorites.post({ _lecture: this.lecture?._id, type: this.type });
                } finally {
                    setTimeout(() => {
                        this.search();
                    }, 500);
                }
            }
        },
    },
};
</script>

<style></style>
