var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-autocomplete', _vm._b({
    attrs: {
      "items": _vm.countries,
      "item-text": "name",
      "item-value": "_id",
      "name": "country"
    },
    on: {
      "change": _vm.onChangeCountry
    },
    model: {
      value: _vm.form._country,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_country", $$v);
      },
      expression: "form._country"
    }
  }, 'v-autocomplete', _vm.$attrs, false))], 1), _c('v-col', [_c('v-fade-transition', [_c('v-autocomplete', _vm._b({
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.hasRegions,
      expression: "hasRegions"
    }],
    attrs: {
      "items": _vm.regions,
      "item-text": "name",
      "item-value": "_id",
      "name": "region",
      "label": "Region"
    },
    on: {
      "change": _vm.onChangeRegion
    },
    model: {
      value: _vm.form._region,
      callback: function ($$v) {
        _vm.$set(_vm.form, "_region", $$v);
      },
      expression: "form._region"
    }
  }, 'v-autocomplete', _vm.$attrs, false))], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }