var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', _vm._b({
    staticClass: "v-card--default",
    attrs: {
      "outlined": "",
      "tile": ""
    }
  }, 'v-card', _vm.$attrs, false), [_vm._t("default"), this.$slots['vCardDefaultContents'] ? _c('div', {
    staticClass: "v-card--default__con px-12 px-lg-24 py-16 py-lg-26"
  }, [_vm._t("vCardDefaultContents")], 2) : _vm._e(), _vm._t("footer")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }