var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', _vm._b({
    attrs: {
      "small": "",
      "icon": "",
      "tile": "",
      "color": "grey-d"
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.toggleFavorite.apply(null, arguments);
      }
    }
  }, 'v-btn', Object.assign({}, _vm.$attrs, {
    loading: _vm.loading
  }), false), [_vm.isFavored ? _vm._t("filled-icon") : _vm._t("empty-icon"), _c('login-to-continue', {
    ref: "LoginToContinue"
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }