<template>
    <v-col cols="6" sm="4" lg="3">
        <v-card-default :to="`${path}/${lecture?._id}`">
            <v-img :aspect-ratio="280/156" :src="lecture?.thumb?.url">
                <v-overlay absolute z-index="0">
                    <v-icon large v-if="lecture?.thumb?.url">mdi-play-circle-outline</v-icon>
                    <v-icon large v-else>mdi-image-broken</v-icon>
                </v-overlay>
                <div class="text-right pt-md-8 pr-md-8">
                    <academy-favorite-btn icon v-bind="{ lecture }">
                        <i slot="filled-icon" class="icon icon-heart-secondary"></i>
                        <i slot="empty-icon" class="icon icon-heart-outlined"></i>
                    </academy-favorite-btn>
                </div>
            </v-img>
            <template slot="vCardDefaultContents">
                <div class="v-card--default__tit">{{ lecture?.name }}</div>
                <div class="v-card--default__date">{{ lecture?.createdAt?.toDate?.() }}</div>
            </template>
        </v-card-default>
    </v-col>
</template>

<script>
const path = "/academy/on-demand";
import VCardDefault from "@/components/client/dumb/v-card-default.vue";
import AcademyFavoriteBtn from "./academy-favorite-btn.vue";

export default {
    components: {
        VCardDefault,
        AcademyFavoriteBtn,
    },
    props: {
        lecture: { type: Object, default: () => ({}) },
    },
    data: () => ({
        path,
    }),
};
</script>

<style></style>
