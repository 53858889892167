<template>
    <v-row no-gutters align="center" class="font-size-14 font-size-md-16 font-spoqa grey-90--text">
        <v-col cols="auto"><span class="pr-4">·</span></v-col>
        <v-col>{{ text }}</v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        text: String,
    },
};
</script>

<style scoped>
.row + .row{
    margin-top:6px !important;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .row + .row{
        margin-top:12px !important;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
</style>