var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--mypage",
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "MYPAGE"
          }
        }, [_c('template', {
          slot: "onlyTitle"
        }, [_c('mypage-navigation')], 1)], 2)];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap mb-20 mb-md-30 mb-lg-40"
  }, [_c('h2', {
    staticClass: "tit tit--sm"
  }, [_vm._v("Product Inquiries")])]), _c('v-data-table', _vm._b({
    staticClass: "v-data-table--default",
    on: {
      "click:row": function (_ref) {
        var _id = _ref._id;
        return _vm.$router.push(`/mypage/product-inquiries/${_id}`);
      }
    },
    scopedSlots: _vm._u([_vm._l(_vm.headers.filter(function (header) {
      return header.hasOwnProperty('formatter');
    }), function (header) {
      return {
        key: `item.${header.value}`,
        fn: function (_ref2) {
          var value = _ref2.value;
          return [_vm._v(" " + _vm._s(header.formatter(value)) + " ")];
        }
      };
    }), {
      key: `item.reply`,
      fn: function (_ref3) {
        var value = _ref3.value;
        return [!value ? _c('v-btn', {
          attrs: {
            "x-small": "",
            "block": ""
          }
        }, [_c('span', [_vm._v("Waiting")])]) : _vm._e(), value ? _c('v-btn', {
          attrs: {
            "x-small": "",
            "block": "",
            "color": "primary"
          }
        }, [_vm._v("Answered")]) : _vm._e()];
      }
    }], null, true)
  }, 'v-data-table', Object.assign({}, _vm.tableAttrs, {
    items: _vm.items,
    headers: _vm.headers
  }), false)), _c('div', {
    staticClass: "v-pagination-wrap"
  }, [_c('v-pagination', {
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function ($event) {
        return _vm.search();
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1), _c('v-fade-transition', [_c('v-overlay', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }],
    attrs: {
      "absolute": "",
      "color": "white",
      "light": ""
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "size": "100",
      "width": "2",
      "indeterminate": "",
      "color": "primary"
    }
  })], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }