var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tabs-underline-inline', [_c('v-tab', {
    attrs: {
      "exact-path": "",
      "to": "/mypage/myinfo"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    }
  }, [_vm._v("Information")])], 1), _c('v-tab', {
    attrs: {
      "exact-path": "",
      "to": "/mypage/product-inquiries"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    }
  }, [_vm._v("Product Inquiries")])], 1), _c('v-tab', {
    attrs: {
      "exact-path": "",
      "to": "/mypage/leave"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    }
  }, [_vm._v("Delete Account")])], 1), _c('v-tab', {
    attrs: {
      "exact-path": "",
      "to": "/mypage/payments"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    }
  }, [_vm._v("Payment History")])], 1), _c('v-tab', {
    attrs: {
      "exact-path": "",
      "to": "/mypage/academy-favorites"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    }
  }, [_vm._v("Liked Lectures")])], 1), _c('v-tab', {
    attrs: {
      "exact-path": "",
      "to": "/mypage/academy-coupons"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    }
  }, [_vm._v("Coupons")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }