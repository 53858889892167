<template>
    <div class="v-application">
        <div class="invoice">
            <div class="invoice__border"></div>
            <div class="invoice__inner">
                <v-row align="center" class="row--x-small" style="flex:none;">
                    <v-col>
                        <div class="font-size-32 font-weight-bold primary--text">INVOICE</div>
                    </v-col>
                    <v-spacer />
                    <v-col>
                        <div class="ml-auto">
                            <v-row class="row--x-small font-size-10">
                                <v-col cols="auto">
                                    <strong>Invoice#</strong>
                                </v-col>
                                <v-col class="text-right">
                                    <span>190323085332212</span>
                                </v-col>
                            </v-row>
                            <v-row class="row--x-small font-size-10 mt-0">
                                <v-col cols="auto">
                                    <strong>Date</strong>
                                </v-col>
                                <v-col class="text-right">
                                    <span>2022-09-23 08:53:32</span>
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>

                <v-divider class="border-2 grey darken-4 mt-30" />

                <div class="pt-36">
                    <div class="mb-12">
                        <div class="font-size-14 font-weight-bold">INVOICE TO</div>
                    </div>
                    <div class="font-size-10">
                        <v-row class="row--x-small">
                            <v-col cols="auto">
                                <div class="w-70px font-weight-bold">Name</div>
                            </v-col>
                            <v-col>
                                <span class="grey--text text--darken-2">Hong Gil-dong</span>
                            </v-col>
                        </v-row>
                        <v-row class="row--x-small mt-2">
                            <v-col cols="auto">
                                <div class="w-70px font-weight-bold">Address</div>
                            </v-col>
                            <v-col>
                                <span class="grey--text text--darken-2">38, Digital-ro 29-gil, Guro-gu, Seoul, Republic of Korea</span>
                            </v-col>
                        </v-row>
                        <v-row class="row--x-small mt-2">
                            <v-col cols="auto">
                                <div class="w-70px font-weight-bold">Contact</div>
                            </v-col>
                            <v-col>
                                <span class="grey--text text--darken-2">+1234-1234-1234</span>
                            </v-col>
                        </v-row>
                    </div>
                </div>

                <div class="py-36">
                    <v-data-table :headers="headers" :items="invoices" hide-default-footer disable-sort disable-filtering disable-pagination class="v-data-table--grey"></v-data-table>

                    <div class="font-size-10 py-16">
                        <v-row class="row--x-small">
                            <v-col cols="auto">
                                <div class="w-70px font-weight-bold">Sub Total</div>
                            </v-col>
                            <v-col class="text-right">
                                <span class="grey--text text--darken-2">1,800,000</span>
                            </v-col>
                        </v-row>
                        <v-row class="row--x-small mt-2">
                            <v-col cols="auto">
                                <div class="w-70px font-weight-bold">Discount</div>
                            </v-col>
                            <v-col class="text-right">
                                <span class="grey--text text--darken-2">0</span>
                            </v-col>
                        </v-row>
                    </div>
                    <v-divider />

                    <div class="font-sixe-14 font-weight-bold primary--text pt-16">
                        <v-row class="row--x-small">
                            <v-col cols="auto">
                                <span>Total:</span>
                            </v-col>
                            <v-col class="text-right">
                                <span>1,800,000</span>
                            </v-col>
                        </v-row>
                    </div>
                </div>

                <v-divider class="border-2 grey darken-4 mt-auto" />

                <div class="pt-36">
                    <v-row no-gutters>
                        <v-col>
                            <v-img width="98" src="/images/logo-text.svg"></v-img>
                        </v-col>
                        <v-col>
                            <div class="font-size-10 ml-auto">
                                <v-row class="row--x-small">
                                    <v-col cols="4">
                                        <strong>Representative</strong>
                                    </v-col>
                                    <v-col>
                                        <span class="grey--text text--darken-2">Yoo Jae-hong</span>
                                    </v-col>
                                </v-row>
                                <v-row class="row--x-small mt-2">
                                    <v-col cols="4">
                                        <strong>Address</strong>
                                    </v-col>
                                    <v-col>
                                        <span class="grey--text text--darken-2">174-10, 821 Jagok-ro, Gangnam-gu, <br/>Seoul (Jagok-dong, Gangnam-gu, <br/>Seoul)</span>
                                    </v-col>
                                </v-row>
                                <v-row class="row--x-small mt-2">
                                    <v-col cols="4">
                                        <strong>Contact</strong>
                                    </v-col>
                                    <v-col>
                                        <span class="grey--text text--darken-2">+1234-1234-1234</span>
                                    </v-col>
                                </v-row>
                                <v-row class="row--x-small mt-2">
                                    <v-col cols="4">
                                        <strong>Website</strong>
                                    </v-col>
                                    <v-col>
                                        <span class="grey--text text--darken-2">www.dentium.co.kr</span>
                                    </v-col>
                                </v-row>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </div>
            <div class="invoice__border"></div>
        </div>
    </div>
</template>

<script>
export default {
    components: {
    },
    data: () => ({
        headers: [
            { width: "7%", align: "center", text: "No.", value: "no" },
            { width: "30%", align: "center", text: "Lecture title", value: "lecture" },
            { width: "18%", align: "center", text: "Speaker", value: "speaker" },
            { width: "15%", align: "center", text: "Payment date", value: "payment" },
            { width: "15%", align: "center", text: "Viewable date", value: "viewable" },
            { width: "15%", align: "center", text: "Amount", value: "amount" },
        ],
        invoices: [
            { no: "02", lecture: "Easy implant surgery,SIMPLY BETTER Part.5", speaker:"Kim Kap-sim", payment: "2022-09-01 18:07:39", viewable: "2022-09-01 ~ 2022-09-30", amount: "1,300,000" },
            { no: "01", lecture: "SIMPLY BETTER Part.5", speaker:"Park Kap-sim", payment: "2022-09-01 18:07:39", viewable: "2022-09-01 ~ 2022-09-30", amount: "500,000" },
        ],
    }),
    computed: {
    },
    mounted() {
    },
    watch: {
    },
    methods: {
    },
};
</script>

<style>
*{
    letter-spacing: 0 !important;
}
.v-application{
    flex-wrap: wrap;
}
.invoice{
    width: 100%;
    max-width:600px;
    margin:auto;
    padding:0 30px;
    border:1px solid var(--border-color);
}
.invoice__inner{
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 800px;
    padding:40px 0 34px;
}
.invoice__border{
    width:100%;
    height:20px;
    background-color:var(--v-primary-base);
}
.v-data-table--grey{
    font-size: 10px !important;
    border-radius: 0;
    border-top:2px solid #111;
}
.v-data-table--grey.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th,
.v-data-table--grey.v-data-table>.v-data-table__wrapper>table>tbody>tr>td:last-child,
.v-data-table--grey.v-data-table>.v-data-table__wrapper>table>tbody>tr>td:not(.v-data-table__mobile-row){
    border-bottom:1px solid var(--border-color)
}
.v-data-table--grey.v-data-table>.v-data-table__wrapper>table>thead>tr>th,
.v-data-table--grey.v-data-table>.v-data-table__wrapper>table>tbody>tr>td{
    font-size: 10px !important;
    padding: 4px;
}
.v-data-table--grey.v-data-table>.v-data-table__wrapper>table>thead>tr>th{
    height: 36px;
    background-color: #f7f8f9;
}
.v-data-table--grey.v-data-table>.v-data-table__wrapper>table>tbody>tr>td{
    color:#666;
}
@media print{
    * {
		-webkit-print-color-adjust: exact;
		print-color-adjust: exact;
    }
    header, footer{ display:none !important; }
}
</style>
