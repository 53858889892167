<template>
    <client-page>
        <template #subHead>
            <sub-visual title="MYPAGE">
                <template slot="onlyTitle">
                    <mypage-navigation></mypage-navigation>
                </template>
            </sub-visual>
        </template>

        <v-container>
            <div class="tit-wrap mb-20 mb-md-30 mb-lg-40">
                <h2 class="tit tit--sm">Coupons</h2>
            </div>
            <v-divider class="border-2 grey-16 mb-30 mb-md-40"/>

            <template v-if="coupons.length < 1">
                <v-row justify="center">
                    <v-col cols="auto">You have no Coupon</v-col>
                </v-row>
            </template>
            <template v-else>
                <v-row>
                    <academy-coupon-list-item v-for="coupon in coupons" :key="coupon._id" v-bind="{ coupon }" />
                </v-row>
                <pagination-component :count="pageCount" :value="page" @change="(page) => $router.replace({ query: { ...$route.query, page } })" />
            </template>
        </v-container>
    </client-page>
</template>

<script>
import api from "@/api";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";
import AcademyCouponListItem from "@/components/client/academy/academy-coupon-list-item.vue";
import PaginationComponent from "@/components/client/control/pagination-component.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        MypageNavigation,
        AcademyCouponListItem,
        PaginationComponent,
    },
    data: () => ({
        limit: 12,
        summary: { totalCount: 0 },
        lecturer: undefined,
        coupons: [],
    }),
    computed: {
        page() {
            return +this.$route.query.page || 1;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        searchKey() {
            return this.$route.query.searchKey;
        },
        searchValue() {
            return this.$route.query.searchValue;
        },
    },
    mounted() {
        this.syncPath().then(this.search);
    },
    watch: {
        page() {
            this.syncPath().then(this.search);
        },
        searchKey() {
            this.syncPath().then(this.search);
        },
        searchValue() {
            this.syncPath().then(this.search);
        },
    },
    methods: {
        async syncPath() {
            if (!!this.searchValue) {
                if (!!this.$route.query.category) this.$router.replace({ query: { ...this.$route.query, category: undefined, page } });
            }
        },
        async search() {
            const { skip, limit, searchKey, searchValue } = this;
            const { summary, coupons } = await api.v1.me.academyCoupons.gets({
                headers: { skip, limit },
                params: { searchKey, searchValue },
            });
            this.summary = summary;
            this.coupons = coupons;
        },
    },
};
</script>

<style></style>
