var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "v-application"
  }, [_c('div', {
    staticClass: "invoice"
  }, [_c('div', {
    staticClass: "invoice__border"
  }), _c('div', {
    staticClass: "invoice__inner"
  }, [_c('v-row', {
    staticClass: "row--x-small",
    staticStyle: {
      "flex": "none"
    },
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('div', {
    staticClass: "font-size-32 font-weight-bold primary--text"
  }, [_vm._v("INVOICE")])]), _c('v-spacer'), _c('v-col', [_c('div', {
    staticClass: "ml-auto"
  }, [_c('v-row', {
    staticClass: "row--x-small font-size-10"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('strong', [_vm._v("Invoice#")])]), _c('v-col', {
    staticClass: "text-right"
  }, [_c('span', [_vm._v("190323085332212")])])], 1), _c('v-row', {
    staticClass: "row--x-small font-size-10 mt-0"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('strong', [_vm._v("Date")])]), _c('v-col', {
    staticClass: "text-right"
  }, [_c('span', [_vm._v("2022-09-23 08:53:32")])])], 1)], 1)])], 1), _c('v-divider', {
    staticClass: "border-2 grey darken-4 mt-30"
  }), _c('div', {
    staticClass: "pt-36"
  }, [_vm._m(0), _c('div', {
    staticClass: "font-size-10"
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-70px font-weight-bold"
  }, [_vm._v("Name")])]), _c('v-col', [_c('span', {
    staticClass: "grey--text text--darken-2"
  }, [_vm._v("Hong Gil-dong")])])], 1), _c('v-row', {
    staticClass: "row--x-small mt-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-70px font-weight-bold"
  }, [_vm._v("Address")])]), _c('v-col', [_c('span', {
    staticClass: "grey--text text--darken-2"
  }, [_vm._v("38, Digital-ro 29-gil, Guro-gu, Seoul, Republic of Korea")])])], 1), _c('v-row', {
    staticClass: "row--x-small mt-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-70px font-weight-bold"
  }, [_vm._v("Contact")])]), _c('v-col', [_c('span', {
    staticClass: "grey--text text--darken-2"
  }, [_vm._v("+1234-1234-1234")])])], 1)], 1)]), _c('div', {
    staticClass: "py-36"
  }, [_c('v-data-table', {
    staticClass: "v-data-table--grey",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.invoices,
      "hide-default-footer": "",
      "disable-sort": "",
      "disable-filtering": "",
      "disable-pagination": ""
    }
  }), _c('div', {
    staticClass: "font-size-10 py-16"
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-70px font-weight-bold"
  }, [_vm._v("Sub Total")])]), _c('v-col', {
    staticClass: "text-right"
  }, [_c('span', {
    staticClass: "grey--text text--darken-2"
  }, [_vm._v("1,800,000")])])], 1), _c('v-row', {
    staticClass: "row--x-small mt-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('div', {
    staticClass: "w-70px font-weight-bold"
  }, [_vm._v("Discount")])]), _c('v-col', {
    staticClass: "text-right"
  }, [_c('span', {
    staticClass: "grey--text text--darken-2"
  }, [_vm._v("0")])])], 1)], 1), _c('v-divider'), _c('div', {
    staticClass: "font-sixe-14 font-weight-bold primary--text pt-16"
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v("Total:")])]), _c('v-col', {
    staticClass: "text-right"
  }, [_c('span', [_vm._v("1,800,000")])])], 1)], 1)], 1), _c('v-divider', {
    staticClass: "border-2 grey darken-4 mt-auto"
  }), _c('div', {
    staticClass: "pt-36"
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', [_c('v-img', {
    attrs: {
      "width": "98",
      "src": "/images/logo-text.svg"
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "font-size-10 ml-auto"
  }, [_c('v-row', {
    staticClass: "row--x-small"
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('strong', [_vm._v("Representative")])]), _c('v-col', [_c('span', {
    staticClass: "grey--text text--darken-2"
  }, [_vm._v("Yoo Jae-hong")])])], 1), _c('v-row', {
    staticClass: "row--x-small mt-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('strong', [_vm._v("Address")])]), _c('v-col', [_c('span', {
    staticClass: "grey--text text--darken-2"
  }, [_vm._v("174-10, 821 Jagok-ro, Gangnam-gu, "), _c('br'), _vm._v("Seoul (Jagok-dong, Gangnam-gu, "), _c('br'), _vm._v("Seoul)")])])], 1), _c('v-row', {
    staticClass: "row--x-small mt-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('strong', [_vm._v("Contact")])]), _c('v-col', [_c('span', {
    staticClass: "grey--text text--darken-2"
  }, [_vm._v("+1234-1234-1234")])])], 1), _c('v-row', {
    staticClass: "row--x-small mt-2"
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('strong', [_vm._v("Website")])]), _c('v-col', [_c('span', {
    staticClass: "grey--text text--darken-2"
  }, [_vm._v("www.dentium.co.kr")])])], 1)], 1)])], 1)], 1)], 1), _c('div', {
    staticClass: "invoice__border"
  })])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mb-12"
  }, [_c('div', {
    staticClass: "font-size-14 font-weight-bold"
  }, [_vm._v("INVOICE TO")])]);

}]

export { render, staticRenderFns }