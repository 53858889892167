var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "MYPAGE"
          }
        }, [_c('template', {
          slot: "onlyTitle"
        }, [_c('mypage-navigation')], 1)], 2)];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap mb-20 mb-md-30 mb-lg-40"
  }, [_c('h2', {
    staticClass: "tit tit--sm"
  }, [_vm._v("Payment History")])]), _c('v-divider', {
    staticClass: "border-2 grey-16"
  }), _c('div', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "5",
      "md": "3",
      "align-self": "center"
    }
  }, [_c('div', {
    staticClass: "pt-16 py-md-34"
  }, [_c('v-img', {
    attrs: {
      "aspect-ratio": 1 / 1,
      "src": ""
    }
  }, [_c('v-overlay', {
    attrs: {
      "absolute": "",
      "z-index": "0"
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-image-broken")])], 1)], 1)], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "7",
      "md": "6",
      "align-self": "center"
    }
  }, [_c('div', {
    staticClass: "px-md-20 px-lg-40 pt-sm-16 py-md-34"
  }, [_c('div', {
    staticClass: "tit tit--xs text-truncate mb-4 mb-md-6"
  }, [_vm._v("seminar name")]), _c('v-divider', {
    staticClass: "my-14 my-md-26"
  }), _c('data-list-item', {
    attrs: {
      "termMd": "4",
      "termLg": "3",
      "termXl": "3",
      "descMd": "8",
      "descLg": "9",
      "descXl": "9",
      "term": "Speaker",
      "desc": "Hong Gil Dong"
    }
  }), _c('data-list-item', {
    attrs: {
      "termMd": "4",
      "termLg": "3",
      "termXl": "3",
      "descMd": "8",
      "descLg": "9",
      "descXl": "9",
      "term": "Payment date",
      "desc": "2022-06-07 12:00:00"
    }
  }), _c('data-list-item', {
    attrs: {
      "termMd": "4",
      "termLg": "3",
      "termXl": "3",
      "descMd": "8",
      "descLg": "9",
      "descXl": "9",
      "term": "Viewable date",
      "desc": "2022-09-01 ~ 2022-09-30 (30 days)"
    }
  })], 1)]), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "3"
    }
  }, [_c('div', {
    staticClass: "d-flex align-center text-center h-100 grey-f8 px-12 py-16"
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "space-between"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto",
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-inline-flex align-center font-size-14 font-size-md-16 font-spoqa"
  }, [_c('span', {
    staticClass: "font-size-18 font-size-md-22 font-weight-medium secondary--text"
  }, [_vm._v("1,200,000")]), _c('span', {
    staticClass: "pl-4 pl-md-6"
  }, [_vm._v("won")])])]), _c('v-col', {
    attrs: {
      "cols": "auto",
      "md": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "w-120px",
    attrs: {
      "small": "",
      "outlined": "",
      "color": "grey-d",
      "to": "/mypage/invoice"
    }
  }, [_vm._v("Invoice"), _c('i', {
    staticClass: "icon icon-down ml-6"
  })])], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', {
    staticClass: "w-120px",
    attrs: {
      "small": "",
      "color": "secondary",
      "to": ""
    }
  }, [_vm._v("Watch")])], 1)], 1)], 1)], 1)], 1)])], 1), _c('v-divider')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }