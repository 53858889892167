var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--mypage",
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "MYPAGE"
          }
        }, [_c('template', {
          slot: "onlyTitle"
        }, [_c('mypage-navigation')], 1)], 2)];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap mb-20 mb-md-30 mb-lg-40"
  }, [_c('h2', {
    staticClass: "tit tit--sm"
  }, [_vm._v("회원탈퇴")])]), _c('v-tabs-items', {
    attrs: {
      "value": _vm.tab
    }
  }, [_c('v-tab-item', {
    attrs: {
      "value": "info"
    }
  }, [_c('div', [_c('div', {
    staticClass: "tit-wrap tit-wrap--hyphen mb-16 mb-md-30"
  }, [_c('h2', {
    staticClass: "tit tit--xs"
  }, [_vm._v("회원탈퇴란?")])]), _c('dot-list-item', {
    attrs: {
      "text": "사이트에 대한 이용해지를 의미합니다."
    }
  }), _c('dot-list-item', {
    attrs: {
      "text": "광고성 이메일의 경우 회원탈퇴 요청 접수 후 24시간 이내에 발송이 중지됩니다."
    }
  }), _c('dot-list-item', {
    attrs: {
      "text": "회원탈퇴 요청 후 48시간이 지난 후, 광고성 이메일/SMS를 접수하시는 경우, 당사에 연락하여 주시기 바랍니다."
    }
  })], 1), _c('div', {
    staticClass: "mt-36 mt-md-46 mt-lg-56"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--hyphen mb-16 mb-md-30"
  }, [_c('h2', {
    staticClass: "tit tit--xs"
  }, [_vm._v("회원정보")])]), _c('dot-list-item', {
    attrs: {
      "text": "회원탈퇴 시 보유하고 계신 쿠폰 및 적립금은 즉시 소멸되며 동일한 아이디로 재가입 하더라도 복원되지 않습니다."
    }
  }), _c('dot-list-item', {
    attrs: {
      "text": "회원탈퇴가 완료되더라도 판/구매자의 권익을 보호하기 위해 다음과 같이 회원정보가 일정기간 보존됨을 알려드립니다."
    }
  }), _c('dot-list-item', {
    attrs: {
      "text": "관계 법령에 의거하여 보존이 필요한 경우에 한하여 보존됩니다."
    }
  }), _c('dot-list-item', {
    attrs: {
      "text": "계약 및 청약철회 등에 관한기록 : 5년"
    }
  }), _c('dot-list-item', {
    attrs: {
      "text": "대금결제 및 재화등의 공급에 관한 기록 : 5년"
    }
  }), _c('dot-list-item', {
    attrs: {
      "text": "소비자의 불만 또는 분쟁처리에 관한 기록 : 3년"
    }
  }), _c('dot-list-item', {
    attrs: {
      "text": "부정이용 등에 관한 기록 : 1년"
    }
  })], 1), _c('div', {
    staticClass: "v-btn-group--bottom"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-md-200px",
    attrs: {
      "block": "",
      "color": "secondary",
      "to": "/mypage/leave/action"
    }
  }, [_vm._v("탈퇴")])], 1)], 1)], 1)]), _c('v-tab-item', {
    attrs: {
      "value": "action"
    }
  }, [_c('div', {
    staticClass: "mb-10"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v("탈퇴 정보")])]), _c('div', {
    staticClass: "table-style table-style--default"
  }, [_c('myinfo-modify-row', {
    attrs: {
      "label": "탈퇴 사유"
    }
  }, [_c('u-text-field', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.withdrawalReason,
      callback: function ($$v) {
        _vm.withdrawalReason = $$v;
      },
      expression: "withdrawalReason"
    }
  })], 1), _c('myinfo-modify-row', {
    attrs: {
      "label": "남기실 말씀"
    }
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "persistent-placeholder": "",
      "hide-details": ""
    },
    model: {
      value: _vm.withdrawalMessage,
      callback: function ($$v) {
        _vm.withdrawalMessage = $$v;
      },
      expression: "withdrawalMessage"
    }
  })], 1)], 1), _c('div', {
    staticClass: "v-btn-group--bottom"
  }, [_c('v-row', {
    staticClass: "row--small",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-md-200px",
    attrs: {
      "outlined": "",
      "block": "",
      "exact": "",
      "color": "grey-d",
      "to": "/mypage/leave"
    }
  }, [_vm._v("Cancel")])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-md-200px",
    attrs: {
      "block": "",
      "color": "secondary"
    },
    on: {
      "click": _vm.withdraw
    }
  }, [_vm._v("탈퇴신청")])], 1)], 1)], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }