<template>
    <v-row align="center">
        <v-col>
            <v-autocomplete v-model="form._country" :items="countries" item-text="name" item-value="_id" name="country" v-bind="$attrs" @change="onChangeCountry" />
        </v-col>
        <v-col>
            <v-fade-transition>
                <v-autocomplete v-show="hasRegions" v-model="form._region" :items="regions" item-text="name" item-value="_id" name="region" label="Region" v-bind="$attrs" @change="onChangeRegion" />
            </v-fade-transition>
        </v-col>
    </v-row>
</template>

<script>
import api from "@/api";
import { ZONE_TYPES } from "@/assets/variables";

export default {
    props: {
        value: { type: Object, default: () => ({}) }, // person
    },
    data: () => ({
        zones: [],
        form: {
            _country: null,
            _region: null,
            _zone: null,
        },
    }),
    computed: {
        countries() {
            return this.zones.filter(({ type }) => type == ZONE_TYPES.COUNTRY.value);
        },
        regions() {
            return this.zones.filter(({ type }) => type == ZONE_TYPES.REGION.value).filter(({ _country }) => _country == this.form?._country);
        },
        hasRegions() {
            return (this.regions?.length || 0) > 0;
        },
    },
    mounted() {
        this.init().then(this.sync);
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        async init() {
            this.zones = (await api.v1.shop.zones.gets())?.zones;
        },
        sync() {
            let { _country, _region, _zone } = this.value;
            this.form = {
                _country,
                _region,
                _zone,
            };
        },
        emit() {
            let { _country, _region, _zone } = this.form;
            this.$emit("input", { ...this.value, _country, _region, _zone });
        },
        onChangeCountry() {
            this.form._zone = this.form._country && !this.hasRegions ? this.form._country : null;
            this.form._region = null;
            this.emit();
        },
        onChangeRegion() {
            if (this.form._zone == this.form._country) return;
            this.form._zone = this.form._region || null;
            this.emit();
        },
    },
};
</script>

<style>
</style>