var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    staticClass: "app--mypage",
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "MYPAGE"
          }
        }, [_c('template', {
          slot: "onlyTitle"
        }, [_c('mypage-navigation')], 1)], 2)];
      },
      proxy: true
    }])
  }, [_c('v-container', [_c('div', {
    staticClass: "tit-wrap mb-20 mb-md-30 mb-lg-40"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("Product Inquiry")])]), _c('form-view', {
    attrs: {
      "code": "consignment-sale",
      "skin": "product-inquiry"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }