<template>
    <client-page class="app--mypage">
        <template v-slot:subHead>
            <sub-visual title="MYPAGE">
                <template slot="onlyTitle">
                    <mypage-navigation></mypage-navigation>
                </template>
            </sub-visual>
        </template>

        <!-- 페이지 콘텐츠 -->
        <v-container>
            <div class="tit-wrap mb-20 mb-md-30 mb-lg-40">
                <h2 class="tit">Product Inquiry</h2>
            </div>

            <form-view code="consignment-sale" skin="product-inquiry" />
        </v-container>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";
import FormView from "@/components/client/form/form-view.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        MypageNavigation,
        FormView,
    },
};
</script>
