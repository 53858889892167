<template>
    <v-card outlined tile v-bind="$attrs" class="v-card--default">
        <slot />
        <div v-if="this.$slots['vCardDefaultContents']" class="v-card--default__con px-12 px-lg-24 py-16 py-lg-26">
            <slot name="vCardDefaultContents" />
        </div>
        <slot name="footer" />
    </v-card>
</template>

<script>
export default {

};
</script>

<style scoped>
.v-card--default:hover{
    border-color: var(--v-secondary-base) !important;
    -webkit-transition: all ease-out .2s; -ms-transition: all ease-out .2s; transition: all ease-out .2s;
}
>>>.v-card--default__tit{
    height: 3em;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.5;
    word-break: break-all;
    overflow: hidden; text-align: left; text-overflow: ellipsis; white-space: normal; word-wrap: break-word; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;
}
>>>.v-card--default__date{
    font-size: 1.4rem;
    color: #909090;
    margin-top:20px;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .v-card--default__con{
        display: flex;
        flex-direction: column;
        min-height: 140px;
    }
    >>>.v-card--default__tit{
        font-size: 1.8rem;
    }
    >>>.v-card--default__date{
        margin-top:auto;
    }
}
@media (min-width:1024px){
    .v-card--default__con{
        min-height: 160px;
    }
}
@media (min-width:1200px){
}
</style>
