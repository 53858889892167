<template>
    <component :is="SkinComponent" v-model="form" :code="code"></component>
</template>

<script>
import api from "@/api";

export default {
    props: {
        skin: { type: String, default: null },
        code: { type: String, default: null },
    },

    data() {
        return {
            form: null,
        };
    },
    computed: {
        SkinComponent() {
            return () => import(`./skin/${this.$props.skin}/view.vue`);
        },
        _form() {
            return this.$route.params?._form;
        },
    },

    mounted() {
        this.init();
    },

    methods: {
        async init() {
            const { form = {} } = await api.v1.forms.get({ _id: this._form, params: { isMine: true } });
            this.form = form;
        },
    },
};
</script>
