<template>
    <client-page class="app--mypage">
        <template v-slot:subHead>
            <sub-visual title="MYPAGE">
                <template slot="onlyTitle">
                    <mypage-navigation></mypage-navigation>
                </template>
            </sub-visual>
        </template>

        <!-- 페이지 콘텐츠 -->
        <v-container>
            <template v-if="me">
                <div class="tit-wrap mb-20 mb-md-30 mb-lg-40">
                    <h2 class="tit tit--sm">Account</h2>
                </div>

                <v-tabs-items :value="tab">
                    <v-tab-item value="view">
                        <div class="table-style table-style--default">
                            <myinfo-row label="Username"> {{ me.username }} </myinfo-row>

                            <template v-if="me.type == PERSON.value">
                                <myinfo-row label="Name"> {{ me.name }} </myinfo-row>
                                <myinfo-row label="Street Address"> {{ me.address.street }} </myinfo-row>
                                <myinfo-row label="City"> {{ me.address.city }} </myinfo-row>
                                <myinfo-row label="State/Province"> {{ me.address.state }} </myinfo-row>
                                <myinfo-row label="Zip/Postal Code"> {{ me.address.zip }} </myinfo-row>
                                <myinfo-row label="Country"> {{ me.zone?.fullName }} </myinfo-row>
                                <myinfo-row label="E-mail Address"> {{ me.email }} </myinfo-row>
                                <myinfo-row label="TEL"> {{ me.phone }} </myinfo-row>
                                <myinfo-row label="Speciality"> {{ me.occupation }} </myinfo-row>
                                <myinfo-row label="Business License Number"> {{ me.businessLicenseNumber }} </myinfo-row>
                            </template>
                        </div>

                        <!-- 하단버튼 -->
                        <div class="v-btn-group--bottom">
                            <v-row justify="center" class="row--small">
                                <v-col cols="6" sm="auto">
                                    <v-btn block color="secondary" class="w-md-200px" @click="goModify">Modify</v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-tab-item>

                    <v-tab-item value="modify">
                        <div class="table-style table-style--default">
                            <myinfo-modify-row label="Username"> {{ me.username }} </myinfo-modify-row>
                            <myinfo-modify-row label="Password">
                                <v-text-field v-model="me.password" type="password" name="password" autocomplete="new-password" outlined hide-details />
                            </myinfo-modify-row>
                            <myinfo-modify-row label="Password Re-type">
                                <v-text-field v-model="me.passwordConfirm" type="password" name="password2" outlined :rules="[() => (me.passwordConfirm && me.passwordConfirm == me.password) || 'Password does not match']" class="mt-n10 mb-n20" />
                            </myinfo-modify-row>

                            <template v-if="me.type == PERSON.value">
                                <myinfo-modify-row label="Name" pointer>
                                    <form-name-input v-model="me" outlined hide-details />
                                </myinfo-modify-row>
                                <myinfo-modify-row label="Street Address"> <v-text-field v-model="me.address.street" outlined hide-details /> </myinfo-modify-row>
                                <myinfo-modify-row label="City"> <v-text-field v-model="me.address.city" outlined hide-details /> </myinfo-modify-row>
                                <myinfo-modify-row label="State/Province"> <v-text-field v-model="me.address.state" outlined hide-details /> </myinfo-modify-row>
                                <myinfo-modify-row label="Zip/Postal Code"> <v-text-field v-model="me.address.zip" outlined hide-details /> </myinfo-modify-row>
                                <myinfo-modify-row label="Country" pointer>
                                    <form-zone-input v-model="me" outlined hide-details />
                                </myinfo-modify-row>
                                <myinfo-modify-row label="E-mail" pointer>
                                    <form-email-input v-model="me.email" outlined hide-details />
                                </myinfo-modify-row>
                                <myinfo-modify-row label="Tel" pointer>
                                    <form-phone-input v-model="me" />
                                </myinfo-modify-row>
                                <myinfo-modify-row label="Speciality" pointer> <v-select v-model="me.occupation" :items="occupations" outlined hide-details /> </myinfo-modify-row>
                                <myinfo-modify-row label="Business License Number" pointer>
                                    <v-text-field v-model="me.businessLicenseNumber" name="businessLicenseNumber" outlined messages="Fill in N/A, if you do not have the number" class="mt-n10 mb-n20" />
                                </myinfo-modify-row>
                            </template>
                        </div>

                        <!-- 하단버튼 -->
                        <div class="v-btn-group--bottom">
                            <v-row justify="center" class="row--small">
                                <v-col cols="6" sm="auto">
                                    <v-btn outlined block color="grey-d" class="w-md-200px" @click="goView()">Cancel</v-btn>
                                </v-col>
                                <v-col cols="6" sm="auto">
                                    <v-btn block color="secondary" class="w-md-200px" @click="save">Save</v-btn>
                                </v-col>
                            </v-row>
                        </div>
                    </v-tab-item>
                </v-tabs-items>
            </template>
        </v-container>
    </client-page>
</template>

<script>
import api from "@/api";
import CryptoAES from "@/plugins/crypto-aes";
import { PERSON_OCCUPATIONS, USER_NOTIFY_METHODS, USER_TYPES } from "@/assets/variables";

import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import MyinfoRow from "@/components/client/mypage/myinfo-row.vue";
import FormNameInput from "@/components/client/join/skin/PERSON/form-name-input.vue";
import FormZoneInput from "@/components/client/join/skin/PERSON/form-zone-input.vue";
import FormPhoneInput from "@/components/client/join/skin/PERSON/form-phone-input.vue";
import MyinfoModifyRow from "@/components/client/mypage/myinfo-modify-row.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";
import FormEmailInput from "@/components/client/join/skin/PERSON/form-email-input.vue";

const { PERSON, BRANCH } = USER_TYPES;
const occupations = Object.values(PERSON_OCCUPATIONS);

export default {
    components: {
        ClientPage,
        SubVisual,
        MyinfoRow,
        FormNameInput,
        FormZoneInput,
        FormPhoneInput,
        MyinfoModifyRow,
        MypageNavigation,
        FormEmailInput,
    },
    props: {
        tab: { type: String, default: "view" },
    },
    data() {
        return {
            ready: false,
            me: null,
            occupations,

            window,
            PERSON,
            BRANCH,
        };
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
    mounted() {
        this.syncRoute().then(this.init);
    },
    methods: {
        async syncRoute() {
            const isPathOkay = /^\/mypage\/myinfo/.test(this.$route.path);
            if (isPathOkay) return;
            this.$router.replace({ path: "/mypage/myinfo" });
        },
        async init() {
            try {
                if (!this.accessToken) {
                    this.$router.push({ path: "/login", params: this.$router.params });
                    return;
                }

                var { user } = await api.v1.me.get();
                if (user?.bornAt) user.bornAt = user.bornAt.toDate();
                if (user?.foundAt) user.foundAt = user.foundAt.toDate();

                this.me = {
                    ...user,
                };

                this.$nextTick(() => (this.ready = true));
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        validate() {
            try {
                if (!this.me.username) throw new Error("Please input your USERNAME");
                if ((this.me.password || this.me.passwordConfirm) && this.me.password != this.me.passwordConfirm) throw new Error("PASSWORD does not match");
                if (this.me.password && this.me.passwordConfirm) this.checkPassword(this.me.password);

                if (!this.me.name_title) throw new Error("Please fill in your TITLE");
                if (!this.me.name_first) throw new Error("Please fill in your FIRST NAME");
                if (!this.me.name_last) throw new Error("Please fill in your LAST NAME");
                // if (!this.me.name) throw new Error("We need your NAME to answer your inquiries");

                if (!this.me._zone && this.me._country) throw new Error("We need your REGION to answer your inquires");
                if (!this.me._zone) throw new Error("We need your COUNTRY to answer your inquires");

                if (!this.me.email) throw new Error("We need your EMAIL to answer your inquiries");

                if (!this.me.phone_country_code) throw new Error("We need your TELEPHONE COUNTRY CODE to answer your inquires");
                if (!this.me.phone_number) throw new Error("We need your TELEPHONE NUMBER to answer your inquires");
                // if (!this.me.phone) throw new Error("We need your TELEPHONE NUMBER to answer your inquires");

                if (!this.me.occupation) throw new Error("We need your SPECIALITY to answer your inquires");

                if (!this.me.businessLicenseNumber) throw new Error("Please, Fill in N/A, If you do not have the number");

                return true;
            } catch (error) {
                alert(error.message);
            }
            return false;
        },

        checkPassword(password) {
            var checkSpecial = /[~!@#$%^&*()_+|<>?:{}]/;
            var checkEng = /[a-zA-Z]/;
            var checkNum = /[0-9]/;

            if (!checkSpecial.test(password)) throw new Error("Password must have one or more of these characters: ~!@#$%^&*()_+|<>?:{}");
            if (!checkEng.test(password)) throw new Error("Password must have a alphabet character");
            if (!checkNum.test(password)) throw new Error("Password must have a number character");

            if (password.length < 8 || 16 < password.length) throw new Error("Password must have length between 8 to 16");

            return true;
        },

        async save() {
            if (this.validate()) {
                await api.v1.me.put({
                    ...this.me,
                    password: this.me.password ? CryptoAES.encrypt(this.me.password) : undefined,
                    password2: undefined,
                });

                alert("Modify Successful");

                this.init();
                this.goView();
            }
        },

        goTop() {
            window.scrollTo({ top: 400, behavior: "smooth" });
        },
        goModify() {
            this.goTop();
            this.$router.push("/mypage/myinfo/modify");
        },
        goView() {
            this.goTop();
            this.$router.push("/mypage/myinfo/view");
        },
        getNotifyName({ method }) {
            return Object.values(USER_NOTIFY_METHODS).find(({ value }) => value == method)?.text;
        },
    },
};
</script>
