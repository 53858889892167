<template>
    <client-page class="app--mypage">
        <template v-slot:subHead>
            <sub-visual title="MYPAGE">
                <template slot="onlyTitle">
                    <mypage-navigation></mypage-navigation>
                </template>
            </sub-visual>
        </template>

        <!-- 페이지 콘텐츠 -->
        <v-container>
            <div class="tit-wrap mb-20 mb-md-30 mb-lg-40">
                <h2 class="tit tit--sm">회원탈퇴</h2>
            </div>

            <v-tabs-items :value="tab">
                <!-- S:안내탭 -->
                <v-tab-item value="info">
                    <div>
                        <div class="tit-wrap tit-wrap--hyphen mb-16 mb-md-30">
                            <h2 class="tit tit--xs">회원탈퇴란?</h2>
                        </div>

                        <dot-list-item text="사이트에 대한 이용해지를 의미합니다." />
                        <dot-list-item text="광고성 이메일의 경우 회원탈퇴 요청 접수 후 24시간 이내에 발송이 중지됩니다." />
                        <dot-list-item text="회원탈퇴 요청 후 48시간이 지난 후, 광고성 이메일/SMS를 접수하시는 경우, 당사에 연락하여 주시기 바랍니다." />
                    </div>

                    <div class="mt-36 mt-md-46 mt-lg-56">
                        <div class="tit-wrap tit-wrap--hyphen mb-16 mb-md-30">
                            <h2 class="tit tit--xs">회원정보</h2>
                        </div>

                        <dot-list-item text="회원탈퇴 시 보유하고 계신 쿠폰 및 적립금은 즉시 소멸되며 동일한 아이디로 재가입 하더라도 복원되지 않습니다." />
                        <dot-list-item text="회원탈퇴가 완료되더라도 판/구매자의 권익을 보호하기 위해 다음과 같이 회원정보가 일정기간 보존됨을 알려드립니다." />
                        <dot-list-item text="관계 법령에 의거하여 보존이 필요한 경우에 한하여 보존됩니다." />
                        <dot-list-item text="계약 및 청약철회 등에 관한기록 : 5년" />
                        <dot-list-item text="대금결제 및 재화등의 공급에 관한 기록 : 5년" />
                        <dot-list-item text="소비자의 불만 또는 분쟁처리에 관한 기록 : 3년" />
                        <dot-list-item text="부정이용 등에 관한 기록 : 1년" />
                    </div>

                    <!-- 하단버튼 -->
                    <div class="v-btn-group--bottom">
                        <v-row justify="center" class="row--small">
                            <v-col cols="6" sm="auto">
                                <v-btn block color="secondary" to="/mypage/leave/action" class="w-md-200px">탈퇴</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-tab-item>
                <!-- E:안내탭 -->

                <!-- S:인풋탭 -->
                <v-tab-item value="action">
                    <div class="mb-10">
                        <h3 class="tit tit--xs">탈퇴 정보</h3>
                    </div>
                    <div class="table-style table-style--default">
                        <myinfo-modify-row label="탈퇴 사유">
                            <u-text-field v-model="withdrawalReason" type="text" />
                        </myinfo-modify-row>
                        <myinfo-modify-row label="남기실 말씀">
                            <v-textarea v-model="withdrawalMessage" outlined persistent-placeholder hide-details />
                        </myinfo-modify-row>
                    </div>

                    <!-- 하단버튼 -->
                    <div class="v-btn-group--bottom">
                        <v-row justify="center" class="row--small">
                            <v-col cols="6" sm="auto">
                                <v-btn outlined block exact color="grey-d" to="/mypage/leave" class="w-md-200px">Cancel</v-btn>
                            </v-col>
                            <v-col cols="6" sm="auto">
                                <v-btn block color="secondary" class="w-md-200px" @click="withdraw">탈퇴신청</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-tab-item>
                <!-- E:인풋탭 -->
            </v-tabs-items>
        </v-container>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";
import MyinfoModifyRow from "@/components/client/mypage/myinfo-modify-row.vue";
import DotListItem from "@/components/client/dumb/dot-list-item.vue";
import UTextField from "@/components/client/dumb/u-text-field.vue";
import api from "@/api";

export default {
    components: {
        ClientPage,
        SubVisual,
        MypageNavigation,
        MyinfoModifyRow,
        DotListItem,
        UTextField,
    },
    props: {
        tab: { type: String, default: "info" },
    },
    data: function() {
        return {
            leave: "main",

            withdrawalReason: null,
            withdrawalMessage: null,
        };
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        tab() {
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
    },
    methods: {
        init() {
            if (!this.accessToken) {
                alert("로그인 이후 이용가능합니다");
                this.$router.push("/login");
                return;
            }
        },
        async withdraw() {
            await api.v1.me.withdraw({
                withdrawalReason: this.withdrawalReason,
                withdrawalMessage: this.withdrawalMessage,
            });

            alert("탈퇴처리 되었습니다");

            this.$store.dispatch("logout").then(() => {
                this.$router.push("/");
            });
        },
    },
};
</script>
